@import "./styles/variables.css";

body {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  background: var(--white);
  border: 1px solid var(--neutral-60);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 2.4%;
  height: 7.65vh;
  width: 29.5vw;
  font-size: 20px;
  line-height: 150%;
}

input:hover {
  border-color: var(--primary-blue-60);
}

input::placeholder{
  color: var(--neutral-40);
}

label {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
