:root {
  /* Primary blue */
  --primary-blue-20: #013159;
  --primary-blue-40: #014D8C;
  --primary-blue-60: #0278DA;
  --primary-blue-80: #80C5FF;
  --primary-blue-100: #D9EEFF;

  /* Neutrals */
  --neutral-00: #222C3B;
  --neutral-20: #425673;
  --neutral-40: #B1BBCA;
  --neutral-60: #DAE4F2;
  --neutral-80: #E7F1FA;
  --neutral-90: #F0F7FD;

  /* Success */
  --green-20: #116E10;
  --green-40: #218318;
  --green-60: #359921;
  --green-80: #8FE573;
  --green-100: #D4FFBF;

  /* Error */
  --error-20: #B71121;
  --error-40: #DB191C;
  --error-60: #FF3223;
  --error-80: #FF7259;
  --error-100: #FF997A;
  /* Warning */
  --warning-20: #B76B03;
  --warning-40: #DB8805;
  --warning-60: #FFA807;
  --warning-80: #FFC445;
  --warning-100: #FFE8AC;

  /* Information */
  --information-20: #182BB1;
  --information-40: #233BD4;
  --information-60: #314FF7;
  --information-80: #637CFA;
  --information-100: #D6DDFC;

  /* Other */
  --white: #fff;
}
