.login-btns-container {
  margin-top: 3.5%;
}

.login-inputs-container {
  display: flex;
  flex-direction: column;
  margin: 2.5% 0;
}

.login-input {
  background: var(--white);
  border: 1px solid var(--neutral-60);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 2.4%;
  height: 7.65vh;
  width: 29.5vw;
  font-size: 20px;
  line-height: 150%;
}

.login-input::placeholder {
  color: var(--neutral-40);
}

.login-input:focus, .login-input:hover {
  transition: .6s;
  border-color: var(--primary-blue-60);
}

.login-inputs-labels {
  color:var(--neutral-00);
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.login-inputs-labels input {
  margin: 1.75% 0;
}

.login-text-container {
  margin: 14% 0 1.5% 0;
}

.login-btn {
  margin-right: 2%
}

@media only screen and (max-width: 1366px) {
  .span-information-text {
    font-size: 16px;
    line-height: 24px;
  }

  .login-input{
    font-size: 16px;
    line-height: 100%;
  }

  .login-inputs-labels{
    font-size: 16px;
    line-height: 24px;
  }

  .login-text-container h1{
    font-size: 26px;
  }
}