@import "../../styles/variables.css";

.main-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.main-left-side-container {
  width: 63%;
}

.main-container-header {
  max-width: 216px;
  margin-left: 10.15%;
  margin-top: 5%;
}

.main-content-container{
  overflow: auto;
  margin: 0 10.15%;
  height: 85vh;
  display: flex;
  flex-direction: column;
}

.main-right-side-container {
  display: flex;
  align-items: flex-end;
  width: 37%;
  background: var(--primary-blue-60);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:'100%';
}

.main-btns-container {
  margin-bottom: 10%;
  width: 100%;
  text-align: center;
  padding: 0 2%;
}

.docs-link-btns {
  background:var(--white);
  margin: 0 1.2%;
}
