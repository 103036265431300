@import "../../styles/variables.css";

.reset-password-container {
  display: flex;
  flex-direction: column;
  margin-top: 8.5%;
}

.go-back-btn {
  background: none;
  width: 2vw;
  max-width: 32px;
  max-height: 32px;
  padding: 0;
}

.reset-password-input {
  margin-top: 1%;
  margin-bottom: 1.1%;
  color: var(--primary-blue-60);
  height: 5vh;
  font-size: 14px;
}

.reset-password-label, .reset-text-container {
  margin: unset;
  margin-top: 3%;
}

.reseted-password-info-container {
  display: flex;
  flex-direction: column;
  margin: 27% 0;
}

.redirect-time-text {
  margin-top: 4%;
  font-weight: 600;
}

.reset-btn {
  margin-top: 3.5%;
}

@media only screen and (max-width: 1366px) {
  .go-back-btn {
    background: none;
    min-width: 24px;
    min-height: 24px;
  }
}