
.span-information-text {
  color: var(--neutral-40);
  font-size: 20px;
  line-height: 32px;
}

.inputs-label {
  display: flex;
  flex-direction: column;
  margin: 3.5% 0;
}

.error-msg {
  display: block;
  color:var(--error-40);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.invalid-input, .invalid-input::placeholder {
  border-color: var(--error-40);
  color: var(--error-40);
}

@media only screen and (max-width: 1366px) {
  .error-msg {
    font-size: 12px;
    line-height: 18px;
  }

  .span-information-text, .inputs-label{
    font-size: 14px;
    line-height: 20px;
  }
}
