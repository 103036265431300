button {
  border: unset;
  font: 500 16px 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  word-break: break-all;
  font-weight: bold;
  max-height: 56px;
  height: 8vh;
  width: 10.41vw;
  padding: 0 6px;
  border-radius: 4px;
}

.primary-btn {
  background: var(--primary-blue-60);
  color: var(--white);
}

.primary-btn:focus, .primary-btn:hover {
  transition: .5s;
  background: var(--primary-blue-40);
}

.primary-btn:disabled {
  background: var(--primary-blue-100);
}

.secondary-btn {
  background: unset;
  border: 2px solid var(--primary-blue-60);
  color: var(--primary-blue-60);
}

.secondary-btn:focus, .secondary-btn:hover {
  transition: .5s;
  border-color: var(--primary-blue-40);
  color: var(--primary-blue-40)
}

.secondary-btn:disabled {
  border-color: var(--primary-blue-100);
  color: var(--primary-blue-100);
}

@media only screen and (max-width: 1366px) {
  button {
    font-size: 12px;
    line-height: 18px;
    min-width: 106px;
  }
}
